import request from '@/utils/request'

export function listParentCategory (query) {
  return request({
    url: '/shop/goods_category/',
    method: 'get',
    params: query
  })
}

export function editParentCategory (parameter, id) {
  return request({
    url: '/shop/goods_category/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function addParentCategory (parameter) {
  return request({
    url: '/shop/goods_category/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
export function deleteParentCategory (parentId) {
  return request({
    url: '/shop/goods_category/' + parentId + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function editChildCategory (parameter, id) {
  return request({
    url: '/shop/goods_category/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function deleteChildCategory (id) {
  return request({
    url: '/shop/goods_category/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function addChildCategory (parameter) {
  return request({
    url: '/shop/goods_category/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function parentCategoryName (query) {
  return request({
    url: '/shop/goods_category/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: query
  })
}

export function detailGoods (id) {
  return request({
    url: '/goods/detail/',
    method: 'get',
    params: { id }
  })
}

export function editGoods (data) {
  return request({
    url: '/mall_mall_goods/',
    method: 'put',
    data
  })
}

export function getAppletConfig () {
  return request({
    url: '/mall/conf/',
    method: 'get'
  })
}
export function editAppletConfig (parameter) {
  return request({
    url: '/mall/conf/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function createAppletConfig (parameter) {
  return request({
    url: '/mall/conf/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function commonStoreConfig (parameter) {
  return request({
    url: '/wms/common_warehouse/corporation_config/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
