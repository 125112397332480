var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_c('a-card',{staticClass:"box-card"},[_c('a-form',{ref:"config",attrs:{"form":_vm.form,"layout":_vm.formLayout}},[_c('a-form-item',{attrs:{"label":"企业","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'corporation_id',
            {
              rules: [
                { required: true, message: '企业名称' },
              ],
            },
          ]),expression:"[\n            'corporation_id',\n            {\n              rules: [\n                { required: true, message: '企业名称' },\n              ],\n            },\n          ]"}],attrs:{"option-label-prop":"tt","show-arrow":false,"filter-option":false,"not-found-content":null,"show-search":"","allow-clear":"","placeholder":"请输入公司名称","default-active-first-option":false},on:{"search":this.corporationSearch,"change":_vm.handlecorporationChange}},_vm._l((_vm.dataSource),function(d){return _c('a-select-option',{key:d.id,attrs:{"tt":d.name}},[_vm._v(" "+_vm._s(d.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"发送人","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['send_name', {
            rules: [
              { required: true, message: '必填项，请填写信息' },
            ],
          }]),expression:"['send_name', {\n            rules: [\n              { required: true, message: '必填项，请填写信息' },\n            ],\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"电话","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['send_mobile', {
            rules: [
              { required: true, message: '必填项，请填写信息' },
            ],
          }]),expression:"['send_mobile', {\n            rules: [\n              { required: true, message: '必填项，请填写信息' },\n            ],\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"拣货单状态回调地址","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pick_list_callback_url', {
            rules: [
              { required: false, message: '必填项，请填写信息' },
            ],
          }]),expression:"['pick_list_callback_url', {\n            rules: [\n              { required: false, message: '必填项，请填写信息' },\n            ],\n          }]"}]})],1),_c('a-form-item',{attrs:{"label":"入库商品状态回调地址","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['commodity_purchase_callback_url', {
            rules: [
              { required: false, message: '必填项，请填写信息' },
            ],
          }]),expression:"['commodity_purchase_callback_url', {\n            rules: [\n              { required: false, message: '必填项，请填写信息' },\n            ],\n          }]"}]})],1)],1),_c('div',{staticClass:"op-container"},[_c('a-button',{staticStyle:{"margin-top":"20px"},attrs:{"type":"primary"},on:{"click":_vm.handlePublish}},[_vm._v("提交")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }